import React from 'react'

import {UploadFileOutlined} from '@mui/icons-material'
import {Button, ButtonProps, Input} from '@mui/material'

export interface FileInputProps {
  id: string
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  disabled?: boolean
  multiple?: boolean
  buttonText?: React.ReactNode | null
  fileExtensionRestrictions?: AcceptOptions[]
  size?: 'small' | 'medium'
  buttonProps?: ButtonProps
  buttonIcon?: React.ReactNode
  noStartMargin?: boolean
}

export type FileExtension =
  | '.csv'
  | '.xlsx'
  | '.docx'
  | '.pdf'
  | '.png'
  | '.jpg'
  | '.jpeg'
  | '.heic'

type AcceptedFileTypes = 'video/*' | 'image/*'

export type AcceptOptions = FileExtension | AcceptedFileTypes

export const FileInput = ({
  id,
  onChange,
  disabled = false,
  multiple = false,
  buttonText = 'Select file',
  fileExtensionRestrictions = [],
  size = 'medium',
  buttonProps,
  buttonIcon = <UploadFileOutlined />,
  noStartMargin = false,
}: FileInputProps): JSX.Element | null => {
  const marginStyle = noStartMargin
    ? {
        '& .MuiButton-startIcon': {
          marginRight: 0, // Override the margin for the startIcon wrapper
        },
      }
    : {}
  return (
    <label htmlFor={id}>
      <Input
        id={id}
        type="file"
        inputProps={{
          accept: fileExtensionRestrictions.join(','),
          multiple,
        }}
        sx={{display: 'none'}}
        onChange={onChange}
        disabled={disabled}
        value=""
      />
      {buttonText !== undefined && (
        <Button
          startIcon={buttonIcon}
          component="span"
          size={size}
          disabled={disabled}
          sx={{
            whiteSpace: 'nowrap',
            ...marginStyle,
          }}
          {...buttonProps}
        >
          {buttonText}
        </Button>
      )}
    </label>
  )
}
