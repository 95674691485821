import * as React from 'react'
import {Route, Switch} from 'react-router-dom'

import {Box, Container, Paper} from '@mui/material'

import {SMBLogin} from 'src/framework/SMBLogin'
import {useCurrentUser} from 'src/hooks/request/useCurrentUser'
import * as Analytics from 'src/util/analytics'

interface Props {
  children: JSX.Element
}

export const AuthContainer = ({children}: Props): JSX.Element => {
  const {user, hasBeenFetched} = useCurrentUser()

  React.useEffect(() => {
    if (hasBeenFetched && !user) {
      Analytics.page('View Login Page', {pageName: 'login'})
    }
  }, [hasBeenFetched, user])

  return user ? (
    children
  ) : (
    <Switch>
      <Route path="/login" exact>
        <>
          <Box
            width="100vw"
            height="100vh"
            flexDirection="column"
            marginTop="20vh"
            display={{xs: 'none', sm: 'flex'}}
          >
            <Container maxWidth="sm">
              <Paper>
                <SMBLogin afterLoginPath="/portal" />
              </Paper>
            </Container>
          </Box>
          <Box marginTop="10vh" display={{xs: 'block', sm: 'none'}}>
            <SMBLogin afterLoginPath="/portal" />
          </Box>
        </>
      </Route>
    </Switch>
  )
}
