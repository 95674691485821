import React from 'react'

import {Box, Collapse, Grid, Paper, Stack} from '@mui/material'
import {useFlags} from 'launchdarkly-react-client-sdk'

import {ContactUsBanner} from 'src/components/portal/ContactUsBanner'
import {NextStepCard} from 'src/components/portal/NextStepCard'
import {PaymentScenarioComparisonSummary} from 'src/components/portal/PaymentScenarioComparisonSummary'
import {PaymentScenarios} from 'src/components/portal/PaymentScenarios'
import {PortalFAQ} from 'src/components/portal/PortalFAQ'
import {PortalTestimonials} from 'src/components/portal/PortalTestimonials'
import {PortfolioCustomersCard} from 'src/components/portal/PortfolioCustomersCard'
import {PriceAppreciationCard} from 'src/components/portal/PriceAppreciationCard'
import {RentAppreciationCard} from 'src/components/portal/RentAppreciationCard'
import {ScreenLayout} from 'src/framework/ScreenLayout'
import {CollapsingGridItem} from 'src/framework/ui/CollapsingGridItem'
import {
  apiPartnersUserBudgetEstimatePath,
  apiPartnersUserCompanyOwnerPath,
  apiPartnersUserCompanyPath,
  apiPartnersUserDealPath,
} from 'src/generated/routes'
import {useTrackPage} from 'src/hooks/analytics/useTrackPage'
import {useCurrentUser} from 'src/hooks/request/useCurrentUser'
import {useShow} from 'src/hooks/request/useShow'
import {useToggle} from 'src/hooks/util/useToggle'
import {
  BudgetEstimate,
  CompanyOwningUser,
  PartnerCompany,
  PartnerDeal,
  User,
} from 'src/types'
import {PortalState} from 'src/types/deal'

export const PortalHomeScreen = (): JSX.Element => {
  const {user} = useCurrentUser()

  if (!user) {
    return <></>
  }

  return <PortalHome user={user} />
}

type PortalHomeProps = {
  user: User
}

export const PortalHome = ({user}: PortalHomeProps): JSX.Element => {
  const {demoApril2024, marketingLaunchJune2024} = useFlags()

  const {entity: company, fetch: refetchCompany} = useShow<PartnerCompany>(
    apiPartnersUserCompanyPath(user.id),
    {
      autoRequest: true,
    },
  )

  const {entity: deal, fetch: refetchDeal} = useShow<PartnerDeal>(
    apiPartnersUserDealPath(user.id),
    {autoRequest: true},
  )

  const {entity: companyOwningUser} = useShow<CompanyOwningUser>(
    apiPartnersUserCompanyOwnerPath(user.id),
    {
      autoRequest: true,
    },
  )

  const {entity: budgetEstimate} = useShow<BudgetEstimate>(
    apiPartnersUserBudgetEstimatePath(user.id),
    {
      autoRequest: true,
    },
  )

  const shouldShowInsightsCards = React.useMemo(() => {
    if (!deal) return false

    if (demoApril2024) return true

    const portalState =
      !deal.smbInbound && deal.portalState === PortalState.LEAD
        ? PortalState.PREQUALIFIED
        : deal.portalState

    return [
      PortalState.PREQUALIFIED,
      PortalState.PREAPPROVAL_UNDER_REVIEW,
      PortalState.PREAPPROVED,
      PortalState.WAITLISTED,
      PortalState.SBA_PREAPPROVED,
      PortalState.SBA_APPLICATION_COMPLETE,
      PortalState.PROPERTY_INELIGIBLE,
      PortalState.LETS_TALK,
    ].includes(portalState)
  }, [deal, demoApril2024])

  const shouldShowEntirePortal = React.useMemo(() => {
    if (!deal) return false

    if (demoApril2024) return true

    return !(
      [PortalState.PREQUALIFIED, PortalState.ADDITIONAL_FINANCIALS].includes(
        deal.portalState,
      ) ||
      (!deal.smbInbound && deal.portalState === PortalState.LEAD)
    )
  }, [deal, demoApril2024])

  const [isPaymentScenarioComparisonRendered, renderPaymentScenarioComparison] =
    useToggle(false)

  const [isRentAppreciationCardRendered, renderRentAppreciationCard] =
    useToggle(false)

  const [isPriceAppreciationCardRendered, renderPriceAppreciationCard] =
    useToggle(false)

  useTrackPage({pageName: 'homepage'})

  return (
    <Box height="100%" width="100%" pb={20}>
      <ContactUsBanner user={user} company={company} />

      <ScreenLayout maxWidth="lg">
        <Grid container spacing={{xs: 2, sm: 3}} sx={{textAlign: 'left'}}>
          <Grid item xs={12}>
            {user && (
              <NextStepCard
                deal={deal}
                company={company}
                refetchCompany={refetchCompany}
                user={user}
                refetchDeal={refetchDeal}
                companyOwningUser={companyOwningUser}
                budgetEstimate={budgetEstimate}
              />
            )}
          </Grid>

          {shouldShowEntirePortal && shouldShowInsightsCards && (
            <>
              <CollapsingGridItem
                xs={12}
                sm={6}
                show={
                  isRentAppreciationCardRendered &&
                  isPriceAppreciationCardRendered
                }
              >
                {deal && user && (
                  <RentAppreciationCard
                    deal={deal}
                    refetchDeal={refetchDeal}
                    onRender={renderRentAppreciationCard}
                  />
                )}
              </CollapsingGridItem>
              <CollapsingGridItem
                xs={12}
                sm={6}
                show={
                  isRentAppreciationCardRendered &&
                  isPriceAppreciationCardRendered
                }
              >
                {deal && user && (
                  <PriceAppreciationCard
                    deal={deal}
                    refetchDeal={refetchDeal}
                    onRender={renderPriceAppreciationCard}
                  />
                )}
              </CollapsingGridItem>
              <CollapsingGridItem
                xs={12}
                show={isPaymentScenarioComparisonRendered}
              >
                {deal && budgetEstimate && user && (
                  <Paper sx={{p: 3}}>
                    {marketingLaunchJune2024 ? (
                      <PaymentScenarioComparisonSummary
                        deal={deal}
                        budgetEstimate={budgetEstimate}
                        refetchDeal={refetchDeal}
                        onRender={renderPaymentScenarioComparison}
                        trackingLocation="portalHome"
                      />
                    ) : (
                      <PaymentScenarios
                        deal={deal}
                        budgetEstimate={budgetEstimate}
                        refetchDeal={refetchDeal}
                        onRender={renderPaymentScenarioComparison}
                        trackingLocation="portalHome"
                      />
                    )}
                  </Paper>
                )}
              </CollapsingGridItem>
            </>
          )}

          {deal?.portalState === PortalState.CLOSED_WON && (
            <Grid item xs={12}>
              <PortfolioCustomersCard />
            </Grid>
          )}
        </Grid>
      </ScreenLayout>
      <ScreenLayout
        maxWidth="lg"
        sx={{
          backgroundColor: 'white',
          pt: 6,
          display: shouldShowEntirePortal ? 'block' : 'none',
        }}
      >
        <Collapse in={shouldShowEntirePortal} timeout={500}>
          <Stack spacing={4}>
            <PortalTestimonials />
            <PortalFAQ />
          </Stack>
        </Collapse>
      </ScreenLayout>
    </Box>
  )
}
